































import { defineComponent, onMounted, Ref, ref } from '@vue/composition-api'
import { api } from 'plugins'
import {
  endpoints,
  toCamelCase,
  showError,
  toSnakeCase,
  convertToWidthCharacter,
  constants
} from 'utils'
import { BoxType, Body, ObjectWrapper, BottomSheetButton } from 'typings'
import { BoxTypeDialog, MasterItemComponent } from 'components'

interface SetUp {
  boxTypes: Ref<BoxType[]>
  showBoxTypeDialog: Ref<boolean>
  isAdd: Ref<boolean>
  readonly: Ref<boolean>
  boxTypeProp: Ref<ObjectWrapper>
  // listNameBoxType: Ref<string[]>
  openBoxTypeDialog: (boxType: BoxType) => void
  getBoxTypes: () => void
  confirmReset: (param: string) => void
  isSorting: Ref<boolean>
  loading: Ref<boolean>
  saveSortOrder: (body: Body) => void
  clearPropsBoxType: () => void
  handleInsertYomiIntoBoxTypeProps: (yomiUpdated: string) => void
  bottomSheetListButton: Array<BottomSheetButton>
}

const BoxTypes = defineComponent({
  components: {
    BoxTypeDialog,
    MasterItemComponent
  },
  props: {},
  setup(props, { root }): SetUp {
    const boxTypes: Ref<BoxType[]> = ref([])
    const isSorting = ref(false)
    const readonly = ref(false)
    const boxTypeProp: Ref<ObjectWrapper> = ref({})
    const showBoxTypeDialog = ref(false)
    // const listNameBoxType: Ref<string[]> = ref([])
    const isAdd = ref(true)
    const { $store, $toast } = root

    const loading = ref(false)

    const bottomSheetListButton: Array<BottomSheetButton> = [
      {
        id: 1,
        text: root.$t('master.box_type.add') as string,
        classes: [],
        icon: 'mdi-plus',
        action: 'on-add',
        disabled: false
      },
      {
        id: 2,
        text: root.$t('master.box_type.sort') as string,
        classes: [],
        icon: 'mdi-sort-ascending',
        action: 'on-sort',
        disabled: false
      },
      {
        id: 3,
        text: root.$t('common.cancel') as string,
        classes: ['red--text'],
        icon: '',
        action: 'on-close',
        disabled: false
      }
    ]

    const getBoxTypes = async (): Promise<void> => {
      loading.value = true
      try {
        const { data } = await api.get(endpoints.BOX_TYPE)
        // need to convert result from snake_case to camelCase
        boxTypes.value = toCamelCase(data)
        // listNameBoxType.value = []
        // listNameBoxType.value = boxTypes.value.map((element: BoxType) => element.name)
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.get_data_failed') as string)
      } finally {
        loading.value = false
      }
    }

    const saveSortOrder = async (body: Body): Promise<void> => {
      const params = toSnakeCase(body)
      try {
        await api.post(`${endpoints.BOX_TYPE}sort`, params)
        $toast.success(root.$t('master.msg.save_order_successful'))
        isSorting.value = false
        await getBoxTypes()
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.save_order_failed') as string)
      }
    }

    const openBoxTypeDialog = (param: BoxType | null): void => {
      if (param) {
        boxTypeProp.value = param
        isAdd.value = false
      } else {
        // default box type prop
        boxTypeProp.value = {}
        isAdd.value = true
      }
      showBoxTypeDialog.value = true
    }

    const confirmReset = async (): Promise<void> => {
      $toast.info(root.$t('master.box_type.box_type_list_is_reset'))
      await getBoxTypes()
    }
    const handleInsertYomiIntoBoxTypeProps = (yomiUpdated: string) => {
      boxTypeProp.value.yomi = JSON.parse(JSON.stringify(yomiUpdated))
      boxTypeProp.value.name = convertToWidthCharacter(boxTypeProp.value.name, 'full')
    }

    const clearPropsBoxType = () => {
      boxTypeProp.value = {
        id: 0,
        name: '',
        shortName: '',
        yomi: '',
        nameEng: '',
        bundleSize: '',
        searchStr: '',
        orderNum: 0
      }
    }

    const setReadonlyFields = async () => {
      const userInfo = await $store.getters.getUserInfo
      readonly.value = userInfo.memberRole === constants.memberRole.ROLE_CHILD_GROWER
    }

    onMounted(() => {
      getBoxTypes()
      setReadonlyFields()
    })

    return {
      boxTypes,
      boxTypeProp,
      showBoxTypeDialog,
      isAdd,
      isSorting,
      loading,
      getBoxTypes,
      confirmReset,
      saveSortOrder,
      openBoxTypeDialog,
      clearPropsBoxType,
      handleInsertYomiIntoBoxTypeProps,
      bottomSheetListButton,
      readonly
    }
  }
})

export default BoxTypes
